import './App.css';

function App() {
  return (
    <div className="App">
      <div className='bg-image'></div>
      <div className='bg-text'>
        <h1>Emily's Blooming Cake Company</h1>
        <p>Coming Soon...</p>
      </div>
    </div>
  );
}

export default App;
